body {
  margin: 0;
  font-family: "Branch", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Branch", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Branch";
  src: url("./branch/Branch.otf") format("otf"),
    url("./branch/Branch.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
