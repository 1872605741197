@font-face {
  font-family: "Branch";
  src: url("../../../branch/Branch.otf") format("otf"),
    url("../../../branch/Branch.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.freeBox__e7HIw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.home2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.svg__lVNeH {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  display: block;
  top: 35px;
  left: auto;
  z-index: 1;
  align-self: auto;
  width: 100px;
  height: 100px;
  bottom: auto;
  flex-shrink: 0;
  margin: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__lVNeH {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
  }
}
.fondo {
  background: url("./images/fe5Ac4271Bc54Bf4940364Ce9Ed93A031.jpeg") center
    center / cover no-repeat;
  transform: translate(0.246px, 0px);
  width: 100%;
  height: 786px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  overflow: hidden;
  align-self: center;
  min-width: 0;
}
.mask2 {
  background: url("./images/mask1.png") center center / cover no-repeat;
  width: auto;
  height: 74px;
  display: block;
  position: fixed;
  top: auto;
  left: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
}
.text__na5Y {
  display: block;
  font-size: 127px;
  font-family: "Branch";
  font-weight: 400;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: sticky;
  top: 500px;
  left: 0px;
  width: auto;
  text-align: center;
  z-index: 1;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__na5Y {
    font-size: 5em;
  }
}
.loveHistory {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 500px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .loveHistory {
    height: auto;
  }
}
.freeBox___7G8T {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.layer12 {
  background: url("./images/layer11.png") center center / cover no-repeat;
  width: 123px;
  height: 82px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.img__o6TUq {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  mix-blend-mode: multiply;
  opacity: 0.899;
  filter: blur(4);
  transform: scale(1.000126491999887, -0.9991886106343766)
    rotate(-159.69875001378713deg) skew(0.01987660591216755deg, 0deg)
    translate(0.033999999999991815px, -0.35999999999999943px);
  width: 169px;
  height: 215px;
  display: block !important;
  transform-origin: top left;
  top: 106px;
  left: 8px;
  align-self: flex-start;
  flex-shrink: 0;
}
.img__o6TUq > picture > img {
  object-fit: cover;
}
.img__o6TUq > .__wab_img-spacer > img {
  object-fit: cover;
}
.img__tqh6W {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  mix-blend-mode: multiply;
  opacity: 0.899;
  filter: blur(4);
  transform: scale(0.9991886708725235, 0.9988413891127164)
    rotate(-20.32114524915997deg) skew(-0.053773350014808555deg, 0deg)
    translate(0px, -0.35999999999999943px);
  width: 169px;
  height: 215px;
  display: block !important;
  transform-origin: top left;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-end;
  right: -28px;
  bottom: 94px;
  margin-right: 15px;
  flex-shrink: 0;
}
.img__tqh6W > picture > img {
  object-fit: cover;
}
.img__tqh6W > .__wab_img-spacer > img {
  object-fit: cover;
}
.freeBox__xj4DC {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__xj4DC {
    justify-content: flex-start;
    align-items: center;
    align-self: center;
  }
}
.text___4R5EV {
  display: block;
  font-size: 68.317px;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(70, 70, 70, 1);
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___4R5EV {
    align-self: center;
    text-align: center;
  }
}
.freeBox__nxe2E {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__jHFrA {
  display: block;
  font-size: 20px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  align-self: center;
  margin: 0% 15%;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__jHFrA {
    margin-bottom: 15%;
  }
}
.counter {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  max-width: 100%;
  background: #51232b;
  z-index: 2;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
.svg___1CJhG {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  opacity: 0.2;
  transform: scale(1.0005583441259185, 1.0007872163364644)
    rotate(166.76931683294436deg) skew(0.05574380580672517deg, 0deg);
  width: 142px;
  height: 163px;
  display: block;
  transform-origin: top left;
  left: 366px;
  top: 129px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg___1CJhG {
    left: 145px;
    top: 100px;
  }
}
.freeBox__aEr1T {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  align-self: center;
  z-index: 1;
  right: auto;
  padding: 8px;
  margin: 0px;
}
.text__fuQvM {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__fuQvM {
    font-size: 40px;
  }
}
.fincaMiravalleGuadarrama2 {
  display: block;
  font-size: 40.57px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .fincaMiravalleGuadarrama2 {
    font-size: 30px;
  }
}
.numbers {
  flex-direction: row;
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  padding: 8px;
}
.numbers > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: calc(0px - 23px);
  width: calc(100% + 23px);
}
.numbers > :global(.__wab_flex-container) > *,
.numbers > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.numbers > :global(.__wab_flex-container) > picture > img,
.numbers
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 23px;
}
.text__hzHbg {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__hzHbg {
    font-size: 60px;
  }
}
.text___71J1H {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___71J1H {
    font-size: 60px;
  }
}
.text__i7Im9 {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__i7Im9 {
    font-size: 60px;
  }
}
.text___5PIfN {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___5PIfN {
    font-size: 60px;
  }
}
.letters {
  flex-direction: row;
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  padding: 8px;
}
.letters > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.letters > :global(.__wab_flex-container) > *,
.letters > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.letters > :global(.__wab_flex-container) > picture > img,
.letters
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.text__hMtY {
  display: block;
  font-size: 10px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  width: 56px;
  flex-shrink: 0;
}
.text__cGp4L {
  display: block;
  font-size: 10px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 56px;
  flex-shrink: 0;
}
.text__texTl {
  display: block;
  font-size: 10px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 56px;
  flex-shrink: 0;
}
.text___9ISi {
  display: block;
  font-size: 10px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 56px;
  flex-shrink: 0;
}
.left1 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 428px;
  height: 494px;
  display: block;
  left: -183px;
  top: 148px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .left1 {
    width: 301px;
    display: block;
  }
}
.r1 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: translate(0.2419999999999618px, 0.24199999999999022px);
  width: 428px;
  height: 494px;
  display: block;
  top: auto;
  left: auto;
  right: -205px;
  bottom: 41px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .r1 {
    height: 850px;
    width: 1099px;
    margin-bottom: 0px;
    margin-top: 101px;
    right: -285px;
    left: auto;
    bottom: auto;
    top: -579px;
    flex-shrink: 0;
  }
}
.r2 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: scale(0.999359795068823, 0.9990556003218459)
    rotate(-17.709724523862754deg) skew(-0.054615473381833965deg, 0deg)
    translate(0px, -0.27899999999999636px);
  width: 428px;
  height: 494px;
  display: block;
  transform-origin: top left;
  top: auto;
  left: auto;
  right: -139px;
  bottom: -33px;
  flex-shrink: 0;
  border-radius: 2px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .r2 {
    height: 762px;
    width: 366px;
    flex-shrink: 0;
  }
}
.svg__oIaS6 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  opacity: 0.2;
  transform: scale(0.9993287747283173, 0.9990615953907939)
    rotate(-15.496489700719634deg) skew(-0.05524996402083251deg, 0deg);
  width: 142px;
  height: 163px;
  display: block;
  transform-origin: top left;
  left: auto;
  top: auto;
  right: 307px;
  bottom: -37px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__oIaS6 {
    right: -29px;
    bottom: -69px;
  }
}
.left3 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: scale(0.999359795068823, 0.9990556003218459)
    rotate(-17.709724523862754deg) skew(-0.054615473381833965deg, 0deg);
  width: 428px;
  height: 494px;
  display: block;
  transform-origin: top left;
  left: -247px;
  top: 224px;
  flex-shrink: 0;
  border-radius: 2px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .left3 {
    top: 261px;
    left: -359px;
    width: 462px;
  }
}
.cronograma {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .cronograma {
    justify-content: flex-start;
    align-items: center;
    align-self: center;
  }
}
.freeBox__wlhjd {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  z-index: 1;
  min-width: 0;
  display: none;
  padding: 30px 20px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__wlhjd {
    display: flex;
  }
}
.text__gtjQp {
  display: block;
  font-size: 35px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.66999;
  color: rgba(70, 70, 70, 1);
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__gtjQp {
    left: auto;
    top: auto;
    width: 100%;
    max-width: 100%;
    font-size: 350%;
    min-width: 0;
  }
}
.rama17 {
  background: url("./images/rama11.png") center center / cover no-repeat;
  transform: scale(1.0005848289875277, 1.0011235139489472)
    rotate(147.4058367323729deg) skew(0.048243885368847036deg, 0deg)
    translate(-0.3780000000000001px, 0.04099999999999682px);
  width: 60px;
  height: 109px;
  display: block;
  transform-origin: top left;
  position: relative;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .rama17 {
    left: 77px;
    top: 54px;
    position: absolute;
    z-index: 1;
  }
}
.homeTimetable {
  background: #ffffff00;
  width: 243px;
  height: 1147px;
  display: block;
  overflow: visible;
  flex-shrink: 0;
}
.img__fNxJt {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.1180000000000021px, -0.15500000000000114px);
  width: 44px;
  height: 36px;
  top: 6.36%;
  left: 23.46%;
  display: none !important;
}
.img__fNxJt > picture > img {
  object-fit: cover;
}
.img__fNxJt > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__fNxJt {
    display: block !important;
  }
}
.img__fileJ {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.1180000000000021px, 0.08800000000002228px);
  width: 50px;
  height: 36px;
  top: 20.66%;
  left: 23.87%;
  display: none !important;
}
.img__fileJ > picture > img {
  object-fit: cover;
}
.img__fileJ > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__fileJ {
    display: block !important;
  }
}
.svg__vA3V {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 52.155px;
  height: 42px;
  display: block;
  top: 390px;
  left: 60px;
}
.svg__hrP5K {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: translate(-0.1180000000000021px, 0.31899999999996px);
  width: 37.541px;
  height: 40.723px;
  top: 566px;
  left: 60px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__hrP5K {
    display: block;
  }
}
.svg___3Kwta {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: translate(-0.1180000000000021px, 0.26500000000010004px);
  width: 45.689px;
  height: 40.723px;
  top: 732px;
  left: 64px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg___3Kwta {
    display: block;
  }
}
.svg__qVhCl {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: translate(-0.1180000000000021px, 0.21199999999998909px);
  width: 51.687px;
  height: 40.723px;
  top: 912px;
  left: 64px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__qVhCl {
    display: block;
  }
}
.text__fjqXo {
  display: block;
  font-size: 37.021px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 143px;
  left: 161px;
  transform: translate(-0.3830000000000098px, 0.2230000000000132px);
}
.text__a02G {
  display: block;
  font-size: 37.021px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 305px;
  left: 162px;
  transform: translate(-0.3830000000000098px, 0.27600000000001046px);
}
.text__wl2Hs {
  display: block;
  font-size: 37.021px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 474px;
  left: 164px;
  transform: translate(-0.3830000000000098px, 0.22299999999995634px);
}
.text__ijPqC {
  display: block;
  font-size: 37.021px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 634px;
  left: 160px;
  transform: translate(-0.3830000000000098px, 0.16999999999995907px);
}
.text__jF0H {
  display: block;
  font-size: 37.021px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 805px;
  left: 167px;
  transform: translate(-0.3830000000000098px, 0.11599999999998545px);
}
.text__nrl96 {
  display: block;
  font-size: 37.021px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 991px;
  left: 167px;
  transform: translate(-0.3830000000000098px, 0.06300000000010186px);
}
.text___5KxkS {
  display: block;
  font-size: 14.654px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 116px;
  left: 59px;
  transform: translate(-0.1180000000000021px, -0.39400000000000546px);
}
.yaEstarasAparcado3 {
  display: block;
  font-size: 14.654px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 279px;
  left: 58px;
  transform: translate(-0.1180000000000021px, -0.3410000000000082px);
}
.text__zzyU {
  display: block;
  font-size: 14.654px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 444px;
  left: 57px;
  transform: translate(-0.1180000000000021px, -0.39400000000000546px);
}
.text__a9K5M {
  display: block;
  font-size: 14.654px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 616px;
  left: 59px;
  transform: translate(-0.1180000000000021px, -0.44700000000000273px);
}
.text__nKjmi {
  display: block;
  font-size: 14.654px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 784px;
  left: 64px;
  transform: translate(-0.1180000000000021px, -0.5px);
}
.text__jy9Ik {
  display: block;
  font-size: 14.654px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 968px;
  left: 64px;
  transform: translate(-0.1180000000000021px, 0.4459999999999127px);
}
.text__pyLnM {
  display: block;
  font-size: 9.255px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 168px;
  left: 61px;
  width: 161px;
  transform: translate(-0.1180000000000021px, 0.09500000000002728px);
}
.text___694Xf {
  display: block;
  font-size: 9.255px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 340px;
  left: 63px;
  width: 161px;
  transform: translate(-0.1180000000000021px, 0.14800000000002456px);
}
.loremIpsumDolorSitAmetConsecteturAdipiscingElitMolestieLeoNuncEgestasViverraNullamPellentesqueArcuSedAt3 {
  display: block;
  font-size: 9.255px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 502px;
  left: 63px;
  width: 161px;
  transform: translate(-0.1180000000000021px, 0.09500000000002728px);
}
.text__bLxSn {
  display: block;
  font-size: 9.255px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 671px;
  left: 62px;
  width: 161px;
  transform: translate(-0.1180000000000021px, 0.04200000000003001px);
}
.text__jDmN2 {
  display: block;
  font-size: 9.255px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 843px;
  left: 64px;
  width: 161px;
  transform: translate(-0.1180000000000021px, -0.010999999999967258px);
}
.text__xpagi {
  display: block;
  font-size: 9.255px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 1025px;
  left: 64px;
  width: 161px;
  transform: translate(-0.1180000000000021px, -0.06400000000007822px);
}
.svg__pQcta {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg)
    translate(0px, 0.32900000000000773px);
  width: 109px;
  height: 2px;
  transform-origin: top left;
  top: 153px;
  left: 47px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__pQcta {
    display: block;
  }
}
.svg__l5Bp {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg)
    translate(0px, 0.38199999999994816px);
  width: 109px;
  height: 2px;
  transform-origin: top left;
  top: 315px;
  left: 48px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__l5Bp {
    display: block;
  }
}
.svg__fyB1P {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg)
    translate(0px, 0.3289999999999509px);
  width: 109px;
  height: 2px;
  transform-origin: top left;
  top: 485px;
  left: 47px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__fyB1P {
    display: block;
  }
}
.svg__tou7B {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg)
    translate(0px, 0.2759999999999536px);
  width: 109px;
  height: 2px;
  transform-origin: top left;
  top: 643px;
  left: 48px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__tou7B {
    display: block;
  }
}
.svg___8Mwhx {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg)
    translate(0px, 0.22299999999995634px);
  width: 109px;
  height: 2px;
  transform-origin: top left;
  top: 816px;
  left: 50px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg___8Mwhx {
    display: block;
  }
}
.svg__n87Yi {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg)
    translate(0px, 0.17000000000007276px);
  width: 109px;
  height: 2px;
  transform-origin: top left;
  top: 1002px;
  left: 50px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__n87Yi {
    display: block;
  }
}
.svg___93R0Z {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  width: 6px;
  height: 1055px;
  top: 60px;
  left: 45px;
  margin-bottom: 0px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg___93R0Z {
    display: block;
  }
}
.svg__hUlEk {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  width: 83px;
  height: 1px;
  top: 60px;
  left: 48px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__hUlEk {
    display: block;
  }
}
.svg__t98Wd {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: rotate(-90deg) translate(-0.5px, 0px);
  width: 44px;
  height: 6px;
  transform-origin: top left;
  top: 61px;
  left: 128px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__t98Wd {
    display: block;
  }
}
.rama25 {
  filter: blur(0.841);
  background: url("./images/rama22.png") center center / cover no-repeat;
  transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg) translateX(0px)
    translateY(0px) translateZ(0px);
  width: 83px;
  height: 132px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 61px;
  left: 387px;
  transform-style: preserve-3d;
}
.rama25 * {
  transform-style: preserve-3d;
}
@media (min-width: 0px) and (max-width: 768px) {
  .rama25 {
    transform-style: preserve-3d;
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .rama25 * {
    transform-style: preserve-3d;
  }
}
.flor25 {
  background: url("./images/flor21.png") center center / cover no-repeat;
  transform: translate(-0.18000000000000682px, 0.3810000000000002px);
  width: 47px;
  height: 40px;
  display: block;
  position: absolute;
  top: 172px;
  left: 307px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .flor25 {
    display: none;
  }
}
.rama15 {
  background: url("./images/rama11.png") center center / cover no-repeat;
  transform: scale(1.000281960249209, 0.9997421124648247)
    rotate(-32.6732774716897deg) skew(-0.04821584122755661deg, 0deg)
    translate(0px, 0.22299999999995634px);
  width: 73px;
  height: 132px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 1806px;
  left: 261px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .rama15 {
    display: none;
  }
}
.flor26 {
  background: url("./images/flor21.png") center center / cover no-repeat;
  width: 78px;
  height: 67px;
  display: block;
  position: absolute;
  top: 1544px;
  left: -125px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .flor26 {
    display: none;
  }
}
.left2 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: scale(0.999359795068823, 0.9990556003218459)
    rotate(-17.709724523862754deg) skew(-0.054615473381833965deg, 0deg);
  width: 428px;
  height: 494px;
  display: block;
  transform-origin: top left;
  left: 0px;
  top: 0px;
  border-radius: 2px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .left2 {
    width: 306px;
    left: -195px;
    top: -266px;
    z-index: 10;
    display: block;
  }
}
.rama14 {
  background: url("./images/rama11.png") center center / cover no-repeat;
  transform: scale(1.0005848289875277, 1.0011235139489472)
    rotate(147.4058367323729deg) skew(0.048243885368847036deg, 0deg)
    translate(0.4399999999999977px, -0.2740000000000009px);
  width: 156px;
  height: 282px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 147px;
  left: 224px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .rama14 {
    display: none;
  }
}
.flor23 {
  background: url("./images/flor21.png") center center / cover no-repeat;
  width: 110px;
  height: 94px;
  display: block;
  position: absolute;
  top: 342px;
  left: auto;
  right: 28px;
  z-index: -10;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .flor23 {
    right: -51px;
    display: block;
  }
}
.frame17 {
  background: #ffffff00;
  width: 947px;
  height: 1162px;
  display: block;
  overflow: visible;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .frame17 {
    display: none;
  }
}
.text__cJ64U {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 263px;
  left: 76px;
  width: auto;
}
.text___3SUFr {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 513px;
  left: 76px;
}
.text__hb3Ae {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 771px;
  left: 79px;
}
.svg___0HW4Z {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: translate(-0.5px, 0px);
  width: 8px;
  height: 826px;
  display: block;
  top: 237px;
  left: 473px;
}
.text__fAQdb {
  display: block;
  font-size: 15.833px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 254px;
  left: 289px;
}
.text__gVbNb {
  display: block;
  font-size: 15.833px;
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 505px;
  left: 306px;
}
.text___9FUgb {
  display: block;
  font-size: 15.833px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 763px;
  left: 381px;
}
.text__l4F1K {
  display: block;
  font-size: 14px;
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 300px;
  left: 225px;
  width: 225px;
}
.loremIpsumDolorSitAmetConsecteturAdipiscingElitMolestieLeoNuncEgestasViverraNullamPellentesqueArcuSedAt2 {
  display: block;
  font-size: 14px;
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 551px;
  left: 225px;
  width: 225px;
}
.text__oJyde {
  display: block;
  font-size: 14px;
  text-align: right;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 809px;
  left: 225px;
  width: 225px;
}
.svg___47Lw {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg);
  width: 257px;
  height: 2px;
  display: block;
  transform-origin: top left;
  top: 284px;
  left: 218px;
}
.svg__dS01 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg);
  width: 259px;
  height: 2px;
  display: block;
  transform-origin: top left;
  top: 535px;
  left: 216px;
}
.svg__d5Egg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg);
  width: 255px;
  height: 2px;
  display: block;
  transform-origin: top left;
  top: 793px;
  left: 220px;
}
.text__xa7LD {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 399px;
  left: 750px;
}
.text__cIdk3 {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 643px;
  left: 750px;
}
.text__umJjH {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.61;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 917px;
  left: 750px;
}
.yaEstarasAparcado2 {
  display: block;
  font-size: 15.833px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 390px;
  left: 487px;
}
.text__tXytF {
  display: block;
  font-size: 15.833px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 634px;
  left: 487px;
}
.text__kj5Fa {
  display: block;
  font-size: 15.833px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(69, 69, 69, 1);
  position: absolute;
  top: 908px;
  left: 487px;
}
.text__oV11F {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 436px;
  left: 487px;
  width: 225px;
}
.text__r9TA {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 680px;
  left: 487px;
  width: 225px;
}
.text__pHpuh {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 954px;
  left: 487px;
  width: 225px;
}
.svg__plXzp {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg);
  width: 265px;
  height: 2px;
  display: block;
  transform-origin: top left;
  top: 420px;
  left: 472px;
}
.svg__jstMo {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg);
  width: 265px;
  height: 2px;
  display: block;
  transform-origin: top left;
  top: 664px;
  left: 472px;
}
.svg___9E0Az {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #464646;
  transform: skew(-0.057295760414500616deg, 0deg);
  width: 265px;
  height: 2px;
  display: block;
  transform-origin: top left;
  top: 938px;
  left: 472px;
}
.img__arUj9 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(-0.26499999999998636px, 0px);
  width: 52px;
  height: 50px;
  display: block !important;
  top: 22.54%;
  left: 51.84%;
}
.img__arUj9 > picture > img {
  object-fit: cover;
}
.img__arUj9 > .__wab_img-spacer > img {
  object-fit: cover;
}
.img__bwgGt {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 46px;
  height: 39px;
  display: block !important;
  top: 34.33%;
  left: 42.87%;
}
.img__bwgGt > picture > img {
  object-fit: cover;
}
.img__bwgGt > .__wab_img-spacer > img {
  object-fit: cover;
}
.svg__rzdG {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 52.155px;
  height: 42px;
  display: block;
  top: 514px;
  left: 491px;
}
.svg__mh89K {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 40.562px;
  height: 44px;
  display: block;
  top: 643px;
  left: 409px;
}
.svg__fnQr9 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 61.707px;
  height: 55px;
  display: block;
  top: 755px;
  left: 491px;
}
.svg__wkmhR {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 64.73px;
  height: 51px;
  display: block;
  top: 908px;
  left: 385px;
}
.text__dWwOc {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.73;
  color: rgba(70, 70, 70, 1);
  position: absolute;
  top: 65px;
  left: 0;
  z-index: 1;
  right: 0;
  width: auto;
}
.rama24 {
  filter: blur(2);
  background: url("./images/rama22.png") center center / cover no-repeat;
  transform: scale(0.9996164264356604, -0.9988041148544118)
    rotate(144.32239791708818deg) skew(-0.03342907576694173deg, 0deg)
    translate(0.40200000000004366px, 0px);
  width: 197px;
  height: 314px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: auto;
  left: auto;
  right: -166px;
  bottom: 807px;
}
.rama13 {
  background: url("./images/rama11.png") center center / cover no-repeat;
  transform: scale(1.000281960249209, 0.9997421124648247)
    rotate(-32.6732774716897deg) skew(-0.04821584122755661deg, 0deg)
    translate(0px, -0.20800000000008367px);
  width: 156px;
  height: 282px;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: auto;
  left: auto;
  right: 167px;
  bottom: -123px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .rama13 {
    bottom: -220px;
    right: 3px;
    display: block;
  }
}
.flor24 {
  background: url("./images/flor21.png") center center / cover no-repeat;
  width: 110px;
  height: 94px;
  display: block;
  position: absolute;
  top: 651px;
  left: -20px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .flor24 {
    left: -39px;
    display: block;
  }
}
.money {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  max-width: 100%;
  background: #51232b;
  z-index: 2;
  min-width: 0;
  flex-shrink: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .money {
    height: auto;
  }
}
.freeBox___7Za1R {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  align-self: center;
  z-index: 1;
  right: auto;
  padding: 8px;
  margin: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___7Za1R {
    justify-content: center;
    align-items: center;
    padding-top: 70px;
  }
}
.text__gn4TC {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 0.67;
  color: rgba(255, 255, 255, 1);
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__gn4TC {
    font-size: 3em;
    margin: 0px 0px 30px;
  }
}
.text__yjwYf {
  display: block;
  font-size: 16px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 491px;
  margin: 15px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__yjwYf {
    width: auto;
    font-size: 14px;
    left: auto;
    top: auto;
    padding: 0px;
    margin: 0% 15% 6%;
  }
}
.freeBox__irZlt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__irZlt {
    width: 331px;
    margin: 0% 20px 10%;
  }
}
.svg___8E2Rd {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 19px;
  height: 19px;
  display: block;
  top: auto;
  left: auto;
  margin-right: 16px;
  flex-shrink: 0;
}
.fincaMiravalleGuadarrama3 {
  display: block;
  font-size: 30px;
  text-align: center;
  font-family: "Branch";
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
}
@media (min-width: 0px) and (max-width: 768px) {
  .fincaMiravalleGuadarrama3 {
    font-size: 1em;
  }
}
.confirmacion {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .confirmacion {
    height: 100%;
    min-height: 0;
  }
}
.freeBox__zGw6G {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
  margin: 5% 15%;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__zGw6G {
    display: flex;
    flex-direction: column;
    flex-column-gap: 0px;
  }
}
.freeBox__u0VaC {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
  margin: 0px 0px 0px 0%;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__u0VaC {
    width: 100%;
    margin-left: 0%;
    min-width: 0;
  }
}
.text__fZA5 {
  display: block;
  font-size: 68.317px;
  text-align: left;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
  margin: 11px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__fZA5 {
    margin-bottom: 20px;
    text-align: center;
  }
}
.text__qr1Ru {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__qr1Ru {
    text-align: center;
    font-size: 14px;
  }
}
.freeBox__vgIf4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
  margin: 0px 0% 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__vgIf4 {
    width: 100%;
    min-width: 0;
  }
}
.textInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 116px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
  margin: 0px 50px;
}
.svg__oa4Rw {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__dMGuB {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 20px;
  display: flex;
}
.svg___1OCTk {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__oRwmp {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__hpsZ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
  margin: 0px 0% 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__hpsZ {
    width: 100%;
    min-width: 0;
  }
}
.text__cRCbk {
  display: block;
  font-size: 68.317px;
  text-align: center;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
  margin: 11px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__cRCbk {
    margin-bottom: 20px;
    text-align: center;
    left: auto;
    top: auto;
  }
}
.direccion {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #e5e5e54d;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .direccion {
    height: auto;
    justify-content: center;
    align-items: center;
  }
}
.freeBox___3SaVo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  margin-right: 15%;
  margin-left: 15%;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___3SaVo {
    display: flex;
    flex-direction: column;
    flex-column-gap: 0px;
    margin: 30% 15%;
  }
}
.freeBox__r1Mlq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
  margin: 0px 0px 0px 0%;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__r1Mlq {
    padding-bottom: 62px;
  }
}
.text__poGpP {
  display: block;
  font-size: 68.317px;
  text-align: left;
  font-family: "Branch";
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__poGpP {
    margin-bottom: 16px;
    text-align: center;
  }
}
.text__x2Y3F {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  padding-bottom: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__x2Y3F {
    text-align: center;
  }
}
.freeBox__p99Us {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 14px 8px 8px;
  margin: 5% 0%;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__p99Us {
    height: 100%;
    min-height: 0;
  }
}
.link {
  position: relative;
  top: auto;
  left: auto;
  display: flex;
}
.img__umrGr {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
  min-height: 0;
}
.img__umrGr > picture > img {
  object-fit: cover;
}
.img__umrGr > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__umrGr {
    width: auto;
    height: auto;
  }
}
.map2 {
  width: 331px;
  height: 324px;
  position: absolute;
  top: 0px;
  left: 187px;
  flex-shrink: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .map2 {
    left: 0px;
    top: 0px;
  }
}
.capturaDePantalla20220511ALas21132 {
  width: 314px;
  height: 311px;
  display: block;
  position: absolute;
  top: 0px;
  left: 17px;
}
.svg__rb4Pb {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  transform: translate(-0.5px, 0px);
  width: 320px;
  height: 320px;
  display: block;
  top: 20px;
  left: 4px;
}
.svg__k9Ki {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 301px;
  height: 288px;
  display: block;
  top: 7.09%;
  left: 0%;
}
.svg___3AqaK {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 33px;
  height: 46px;
  display: block;
  top: 84px;
  left: 78px;
}
.svg__xsAy {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: translate(0.4880000000000564px, -0.13100000000000023px);
  width: 19.431px;
  height: 15.648px;
  display: block;
  top: 91px;
  left: 84px;
}
.frame18 {
  flex-direction: row;
  background: rgba(195, 195, 195, 1);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 136px;
  left: 85px;
  border-radius: 3.007px;
  padding: 2.506px 4.511px;
}
.frame18 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 5.012px);
  width: calc(100% + 5.012px);
}
.frame18 > :global(.__wab_flex-container) > *,
.frame18 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame18 > :global(.__wab_flex-container) > picture > img,
.frame18
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5.012px;
}
.text__k0JbU {
  display: block;
  font-size: 5.012px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
}
.frame19 {
  flex-direction: row;
  background: rgba(195, 195, 195, 1);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 139px;
  left: 259px;
  border-radius: 3.007px;
  padding: 2.506px 4.511px;
}
.frame19 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 5.012px);
  width: calc(100% + 5.012px);
}
.frame19 > :global(.__wab_flex-container) > *,
.frame19 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame19 > :global(.__wab_flex-container) > picture > img,
.frame19
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5.012px;
}
.text___9I6QE {
  display: block;
  font-size: 5.012px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
}
.frame20 {
  flex-direction: row;
  background: rgba(195, 195, 195, 1);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 228px;
  left: 199px;
  border-radius: 3.007px;
  padding: 2.506px 4.511px;
}
.frame20 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 5.012px);
  width: calc(100% + 5.012px);
}
.frame20 > :global(.__wab_flex-container) > *,
.frame20 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame20 > :global(.__wab_flex-container) > picture > img,
.frame20
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5.012px;
}
.text__p9WD {
  display: block;
  font-size: 5.012px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
}
.frame21 {
  flex-direction: row;
  background: rgba(195, 195, 195, 1);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 71px;
  left: 214px;
  border-radius: 3.007px;
  padding: 2.506px 4.511px;
}
.frame21 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 5.012px);
  width: calc(100% + 5.012px);
}
.frame21 > :global(.__wab_flex-container) > *,
.frame21 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame21 > :global(.__wab_flex-container) > picture > img,
.frame21
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5.012px;
}
.text__g6Vpw {
  display: block;
  font-size: 5.012px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
}
.frame22 {
  flex-direction: row;
  background: rgba(195, 195, 195, 1);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 160px;
  left: 68px;
  border-radius: 3.007px;
  padding: 2.506px 4.511px;
}
.frame22 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 5.012px);
  width: calc(100% + 5.012px);
}
.frame22 > :global(.__wab_flex-container) > *,
.frame22 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame22 > :global(.__wab_flex-container) > picture > img,
.frame22
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5.012px;
}
.text__eXtld {
  display: block;
  font-size: 5.012px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
}
.frame23 {
  flex-direction: row;
  background: rgba(195, 195, 195, 1);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 186px;
  left: 211px;
  border-radius: 3.007px;
  padding: 2.506px 4.511px;
}
.frame23 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 5.012px);
  width: calc(100% + 5.012px);
}
.frame23 > :global(.__wab_flex-container) > *,
.frame23 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame23 > :global(.__wab_flex-container) > picture > img,
.frame23
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5.012px;
}
.text__wjq87 {
  display: block;
  font-size: 5.012px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
}
.frame24 {
  flex-direction: row;
  background: rgba(195, 195, 195, 1);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 154px;
  left: 126px;
  border-radius: 3.007px;
  padding: 2.506px 4.511px;
}
.frame24 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 5.012px);
  width: calc(100% + 5.012px);
}
.frame24 > :global(.__wab_flex-container) > *,
.frame24 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame24 > :global(.__wab_flex-container) > picture > img,
.frame24
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5.012px;
}
.text__c5Lh5 {
  display: block;
  font-size: 5.012px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
}
.frame25 {
  flex-direction: row;
  background: rgba(195, 195, 195, 1);
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 85px;
  left: 50px;
  border-radius: 3.007px;
  padding: 2.506px 4.511px;
}
.frame25 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 5.012px);
  width: calc(100% + 5.012px);
}
.frame25 > :global(.__wab_flex-container) > *,
.frame25 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame25 > :global(.__wab_flex-container) > picture > img,
.frame25
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5.012px;
}
.text__ztti7 {
  display: block;
  font-size: 5.012px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(0, 0, 0, 1);
  position: relative;
}
.svg__gLsS {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #000;
  width: 12px;
  height: 11px;
  display: block;
  top: 53.08%;
  left: 49.24%;
}
